<template lang="pug">
ui-tooltip.ui-warning(:placement="placement")
  slot(name="warning")
    ui-icon.warning-icon(
      :size="size === 'small' ? 12 : 16"
      :icon="type === 'info' ? UiIconNames.Icon_InfoNew : UiIconNames.Icon_Question"
    )
  template(#content)
    slot
      .warning {{ label }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import UiIconNames from "@/components/ui/icon/UiIconNames";

import UiIcon from "~/components/ui/icon/UiIcon.vue";
import UiTooltip from "~/components/ui/tooltip/UiTooltip.vue";

export default defineComponent({
  name: "UiWarning",
  components: {
    UiIcon,
    UiTooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'info'|'question'>,
      default: 'info',
    },
    size: {
      type: String as PropType<'small'|'default'>,
      default: 'default',
    },
    placement: {
      type: String as PropType<'top' | 'left' | 'right' | 'bottom'>,
      default: 'bottom',
    },
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.warning-icon {
  color: var(--main-red-color);
}

.ui-warning {
  display: flex;
  align-items: center;
}

.warning {
  max-width: 420px;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}
</style>
